<template>
	<div id="seedEdit">
		<page-seed-edit :is-shop="0"></page-seed-edit>
	</div>
</template>

<script>
import pageSeedEdit from '@/components/layout/land/page-seed-edit.vue'
import { onBeforeRouteLeave } from 'vue-router'
import store from '@/store'
	export default{
		components:{
			pageSeedEdit
		},
		setup(){
			onBeforeRouteLeave((to,from,next)=>{
				if( to.path == '/land/seedList'){
					store.dispatch("setKeepPage",{name:to.name,type:1})
				}else{
					store.dispatch("setKeepPage",{name:to.name,type:2})
				}
				next()
			})
		}
	}
</script>

<style>

</style>
